/* eslint-disable */
export const utils = {
  pageLock: function () {
    document.addEventListener("touchmove", utils.pageLockHandler, false)
  },
  pageUnlock: function () {
    document.removeEventListener("touchmove", utils.pageLockHandler, false)
  },
  pageLockHandler: function (e) {
    e.preventDefault();
  }
};

/*
金额格式化
    */
export function formatCurrency (s) {
  if (!/^(-?\d+)(\.\d*)?$/.test(s)) {
    return 'invalid value';
  }

  let sign = '';
  s = Number(s);
  if (s < 0) {
    sign = '-';
  } else {
    sign = '';
  }
  s = Math.abs(s);
  if (/^\d+$/.test(s)) {
    return (sign + (s + '').replace(/\B(?=(\d{3})+$)/g, ',') + '.00');
  }
  if (/^(\d+)\.(\d+)$/.test(s)) {
    s = s + '0';
    var v = s.split('.');
    var f = (v[0] + '').replace(/\B(?=(\d{3})+$)/g, ',');
    var h = v[1].substring(0, 2);
    return (sign + f + '.' + h);
  }
}

/**
 * 获得当前地址栏传递参数
 * @returns {null}
 */
export function getParams (name, url = window.location.href) {
  const reg = new RegExp( '(^|&)' + name + '=([^&]*)(&|$)', 'i' )
  const r = url.split('?')[1] && url.split('?')[1].match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  } else {
    return null
  }
}

export function removeUrlParam (name, url = window.location.href) {
  const reg = new RegExp( '(\\?|&)' + name + '=[^&]*(&)?', 'g')
  return url.replace(reg, function(p0, p1, p2) {
    return p1 === '?' || p2 ? p1 : ''
  })
}

/**
 * 获得当前地址栏全部参数字符串
 * @returns {null}
 */
export function getAllUrlParams (url) {
  url = url ? url : window.location.href;
  var params = url.indexOf("#") > -1 ? url.substring(url.indexOf("?"), url.indexOf("#")) : url.substring(url.indexOf("?"))
  return params;
}

export function isInWeiXin () {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  }
  return false;
}


/*
 操作 sessionstorage
 */
export function setStorage (name, obj) {
  let str = JSON.stringify(obj)
  localStorage[name] = str
}

export function getStorage (name) {
  if (localStorage[name]) {
    return JSON.parse(localStorage[name])
  } else {
    return false
  }
}

/**
 * 仅限数字
 * @return number
 */
export function onlyNumber (input) {
  var val = input.val();
  var _val = ""

  if ('' != val.replace(/^[0-9]\d*$/, '')) {
    var matchVal = val.match(/\d{1,}\.{0,1}\d{0,2}/);

    if (matchVal == null) {
      _val = ""
    } else {
      _val = matchVal;
    }

    input.val(_val)
  }
}


/*
 * 格式化日期
 * formatDate('yyyy-MM-dd HH:mm:ss','时间戳')
 * */
export function formatDate (format, time) {
  var t = time != undefined ? new Date(time) : new Date();
  var tf = function (i) {
    return (i < 10 ? '0' : '') + i
  };
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
    switch (a) {
      case 'yyyy':
        return tf(t.getFullYear());
        break;
      case 'MM':
        return tf(t.getMonth() + 1);
        break;
      case 'mm':
        return tf(t.getMinutes());
        break;
      case 'dd':
        return tf(t.getDate());
        break;
      case 'HH':
        return tf(t.getHours());
        break;
      case 'ss':
        return tf(t.getSeconds());
        break;
    }
  })
}


// dataURI转blob 上传文件使用 zx
export function dataURItoBlob (dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

// jsonp请求的简单封装
export function feJsonp (url, queryObj, successFn, failFn, timeout) {
  timeout = timeout || 100000

  let queryText = url.indexOf('?') > -1 ? '&' : '?'
  for (let key in queryObj) {
    queryText += `${key}=${queryObj[key]}&`
  }

  let cbname = 'fejp_' + Date.parse(new Date())
  let srcText = `${url}${queryText}callback=${cbname}`

  let scriptEl = document.createElement("script")
  scriptEl.src = srcText

  let isout = false
  const timeId = window.setTimeout(function () {
    isout = true
    failFn()
    document.body.removeChild(scriptEl)
  }, timeout)

  window[cbname] = function (res) {
    if (isout) {
      return false
    } else {
      successFn(res)
      window.clearTimeout(timeId)
      document.body.removeChild(scriptEl)
    }
  }


  document.body.appendChild(scriptEl)
}

export const docCookies = {
  getItem: function (sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys: /* optional method: you can safely remove it! */ function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  }
}
